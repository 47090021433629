var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.test.name))]),_c('v-card',{staticClass:"mt-8",attrs:{"outlined":"","loading":_vm.loading}},[_c('v-item-group',[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"tile":""}},_vm._l((_vm.questions),function({
						id,
						text,
						result,
						answers,
						userAnswers,
					},index){return _c('v-item',{key:id,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-sheet',{staticClass:"px-4 pt-4 pb-3 cursor-pointer",class:active ? '' : (result ? 'success--text' : 'pink--text lighten-4'),attrs:{"color":active ? 'grey lighten-4' : (result ? 'success lighten-3' : 'pink lighten-4'),"tile":""},on:{"click":toggle}},[_c('div',{staticClass:"transition-swing"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(text)+" ")]),_c('v-expand-transition',[(active)?_c('v-responsive',[_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((answers),function(answer){return _c('v-chip',{key:answer.id,staticClass:"my-1",attrs:{"color":userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success lighten-3' : 'pink lighten-4') : undefined,"text-color":userAnswers.indexOf(answer.id) >= 0 ? (answer.is_correct ? 'success' : 'pink') : undefined,"label":""}},[(answer.is_correct)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e(),_vm._v(" "+_vm._s(answer.text)+" "),(userAnswers.indexOf(answer.id) >= 0)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-pencil-circle-outline ")]):_vm._e()],1)}),1),_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Умовні позначення ")]),_c('div',{staticClass:"grey--text mb-2"},[_c('div',[_c('v-icon',[_vm._v(" mdi-check-circle-outline ")]),_vm._v(" - правильна відповідь на питання ")],1),_c('div',[_c('v-icon',[_vm._v(" mdi-pencil-circle-outline ")]),_vm._v(" - відповідь, обрана учнем ")],1)])]):_vm._e()],1)],1)]}}],null,true)})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }